
thead, tbody {
    float: left;
    width: 100%;
}
tbody {
    overflow: scroll !important;
    overflow-x: hidden !important;
    height: 500px;
}
tr {
    width: 100%;
    display: table;
    text-align: left;
}

.proxyTokens {
    overflow: scroll !important;
    overflow-x: hidden !important;
}