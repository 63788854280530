html {
  height: 100%;
  background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%);
  overflow: hidden;
}

#avatarButton {
  padding: 0px;
  vertical-align: middle;
  width: 70px;
  height: 70px;
  border-radius: 50%;      
}

@media (max-width: 769px) {
  #menuItem {
    display: none;
  }
}

#side-drawer {
  padding-left: 150px;
  margin-right: 10px;
}

@media (min-width: 769px) {
  #side-drawer {
    display: none;
  }
}

#dashboardMenu.active.item {
  border-color: #57FE76!important;
  color: #57FE76!important;
  font-weight: bold;
  letter-spacing: 2px;
} 

#dashboardMenu2.active.item {
  border-color: #59CAFF!important;
  color: #59CAFF!important;
  font-weight: bold;
  letter-spacing: 2px;
}

#dashboardMenu.item:hover {
  background: rgba(0,0,0,.09);
} 

#dashboardMenu.active.item:hover {
  background: rgba(0,0,0,.3);
} 

#dashboardMenu2.item:hover {
  background: rgba(0,0,0,.09);
} 

#dashboardMenu2.active.item:hover {
  background: rgba(0,0,0,.3);
} 

.react-calendar {
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
}
