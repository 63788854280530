.header {
    margin-top: 50px;
}

.faq-p {
    color: #b2bec3;
    font-size: large;
}

.faq-h1 {
    font-size: 40px;
}